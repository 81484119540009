.navbar-section {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .navbar-container {
    // flex-direction: row;

    @include smallDesktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }

    .navbar__logo {
      height: 70px;
      display: flex;
      justify-content: center;

      @include smallDesktop {
        height: 150px;
      }
      @include miniPortraitTablet {
        height: 130px;
      }

      .navbar__logo--img {
        cursor: pointer;
        height: 100%;
      }
    }

    .navbar-right {
      display: flex;
      justify-content: center;
      gap: 16px;
      margin-top: 30px;

      @include smallDesktop {
        margin-top: 0;
      }

      .large-device-menu {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-right: 20px;

        @include smallDesktop {
          display: none;
        }

        a {
          color: #fff;
          font-size: 16px;
          padding: 10px;
          cursor: pointer;

          &:hover {
            color: gold;
          }
        }
      }

      // small-device-menu
      .small-device-menu {
        display: none;

        @include smallDesktop {
          display: block;
        }

        .menu-bar {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;

          .small-device__sidebar-toggle {
            background-color: gold;
            color: #fff;
            border-radius: 5px;
            padding: 8px;
            user-select: none;
            width: 1em;
            height: 1em;
            display: inline-block;
            fill: currentcolor;
            flex-shrink: 0;
            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            font-size: 24px;
            cursor: pointer;
          }
        }

        .sidebar-wrapper {
          display: none;
          .sidebar {
            position: fixed;
            top: 175px;
            left: 0;
            color: #000;
            background-color: #fff;
            z-index: 1000;
            width: 100%;
            transition: all 1s;
            transition-duration: 1000ms;

            @include miniPortraitTablet {
              top: 150px;
            }

            .sidebar__header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid black;

              .header__closeButton {
                .button {
                  background-color: transparent;
                  border: none;
                  color: #000;
                  padding: 24px;

                  .button-icon {
                    font-size: 20px;
                  }
                }
              }
            }

            .sidebar__body {
              .body__menu {
                font-weight: 500;
                line-height: 1.6;
                letter-spacing: 0.0075em;
                font-size: 15px;
                list-style: none;
                border-bottom: 1px solid #dddddd;
                padding: 12px 24px;
                a {
                  text-decoration: none;
                  color: #1d394b;
                  font-weight: 500;
                }
              }
            }
          }
          .sidebar__backdrop {
            position: absolute;
            top: 0;
            right: 0;
            width: 100vw;
            height: 100vh;
          }
        }

        .open {
          display: block;
        }
      }
    }
  }
}

// Dropdown Menu
.dropdown {
  position: relative;
  display: inline-block;

  &:hover {
    .dropdown-content {
      display: block;
    }
  }

  .dropdown__btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .dropdown-content {
    border-radius: 5px;
    display: none;
    position: absolute;
    right: 0;
    min-width: 220px;
    z-index: 1;
    padding-top: 20px;
    background-color: #000;

    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;

      &:hover {
        font-weight: 700;
      }
    }
  }
}
