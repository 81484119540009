.header-section {
  transition: background-image 0.3s ease-in-out;
  position: relative;
  width: 100%;
  min-height: 650px;
  height: 100vh;

  @include smallDesktop {
    min-height: 500px;
    height: 500px;
  }
  @include miniPortraitTablet {
    min-height: 500px;
    height: 530px;
  }

  .section-layer {
    position: absolute;
    background-color: #000000ac;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .header__title {
    font-size: 80px;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    color: #fff;
    position: absolute;
    top: 200px;
    z-index: 2;
    width: 100%;
    text-align: center;

    @include smallDesktop {
      font-size: 56px;
      top: 150px;
    }
    @include miniPortraitTablet {
      font-size: 35px;
      top: 200px;
    }
  }

  .header__paragraph {
    font-family: "Quicksand", sans-serif;
    color: #fff;
    position: absolute;
    top: 380px;
    z-index: 2;
    width: 100%;
    text-align: center;
    font-size: 20px;

    @include smallDesktop {
      top: 280px;
    }
    @include miniPortraitTablet {
      top: 290px;
      font-size: 18px;
    }
  }

  .header__button {
    position: absolute;
    top: 450px;
    z-index: 2;
    width: 100%;
    text-align: center;

    @include smallDesktop {
      top: 350px;
    }
    @include miniPortraitTablet {
      top: 360px;
    }

    .commonButton {
      margin: 0 auto;
    }
  }
}
