.ourServices-section {
  padding: 100px 0;

  @include tablet {
    padding: 50px 0;
  }

  .ourServices-body {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @include smallDesktop {
      grid-template-columns: repeat(3, 1fr);
    }
    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include miniPortraitTablet {
      grid-template-columns: repeat(1, 1fr);
    }
    .ourServices__card {
      position: relative;
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: center;
      width: 100%;
      height: 480px;
      border-radius: 10px;

      @include miniPortraitTablet {
        width: 80%;
        margin: 0 auto;
      }

      .ourServices__card--overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #00000073;
      }

      .ourServices__card--header {
        position: absolute;
        width: 100%;
        top: 30%;
        z-index: 2;
        text-align: center;
        font-size: 28px;
      }
      .ourServices__card--title {
        position: absolute;
        width: 100%;
        z-index: 2;
        font-size: 22px;
        line-height: 1.2;

        @include smallDesktop {
          width: 80%;
          left: 10%;
        }
      }
      .titleOne {
        top: 48%;
      }
      .titleTwo {
        top: 60%;
      }
      .titleThree {
        top: 72%;
      }
    }
  }
}
