.common-divider {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  .divider__text {
    background-color: #000;
    padding: 8px;
    z-index: 2;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    font-family: "Cinzel", Georgia, serif;

    @include tablet {
      font-size: 28px;
    }
  }
  .divider__text--hr {
    position: absolute;
    height: 1px;
    width: 100%;
    background: #fff;
    top: calc(50% + 0.5px);
    z-index: 0;
  }
}
