.backgroundOverlay-section {
  background-image: url("https://srisrisahajevents.com/wp-content/uploads/2020/05/cta-bg-free-img.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  height: 230px;
  position: relative;

  @include smallDesktop {
    height: 280px;
  }

  .backgroundOverlay-wrapper {
    position: relative;

    .backgroundOverlay__heading {
      font-size: 48px;
      font-weight: 500;
      font-family: "Quicksand", sans-serif;
      position: absolute;
      z-index: 2;
      top: 85px;
      left: 0;
      margin: 0;

      @include smallDesktop {
        font-size: 35px;
        width: 100%;
        text-align: center;
        top: 60px;
      }
      @include miniPortraitTablet {
        font-size: 30px;
      }
    }
    .backgroundOverlay__button {
      position: absolute;
      z-index: 2;
      top: 90px;
      right: 0;

      @include smallDesktop {
        width: 100%;
        top: 160px;
      }
      @include miniPortraitTablet {
        top: 175px;
      }

      .commonButton {
        margin: 0 auto;
      }
    }
  }

  .backgroundOverlay-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000073;
  }
}
