.commonButton {
  background-color: #ffba00;
  color: #fff;
  transition: all 0.3s;
  border: none;
  padding: 18px 32px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;

  &:hover {
    color: #000;
  }

  letter-spacing: 1.5px;
  .commonButton--text {
    font-weight: 600;
  }
  .commonButton--icon {
    font-weight: 600;
  }
}
