.gallery-section {
  padding: 100px 0;

  @include tablet {
    padding: 50px 0;
  }
  @include miniPortraitTablet {
    width: 90%;
    margin: 0 auto;
  }

  .gallery__top--paragraph {
    text-align: center;
    margin-top: 30px;
  }

  .gallery__body {
    margin-top: 60px;

    img {
      border-radius: 5px;
    }
  }
}
