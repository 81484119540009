.commonSlider-section {
  .commonSlider__body {
    .commonSlider__item {
      .commonSlider__item--img {
        width: 96%;
        margin: 0 auto;
      }
    }
  }

  .slick-arrow {
    z-index: 2;
  }
  .slick-prev {
    left: 15px;
  }
  .slick-next {
    right: 15px;
  }
  .slick-prev:before {
    content: "⮜";
  }
  .slick-next:before {
    content: "⮞";
  }
}
