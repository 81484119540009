.commonSectionHeader {
  text-align: center;
  .commonSectionHeader--text {
    font-size: 3rem;
    font-weight: 500;
    font-family: "Quicksand", sans-serif;
    color: #bb8534;

    @include smallDesktop {
      font-size: 35px;
    }
    @include miniPortraitTablet {
      font-size: 30px;
    }
  }
}
