.contactUs-section {
  padding-bottom: 100px;

  @include tablet {
    padding-bottom: 50px;
  }
  @include miniPortraitTablet {
    width: 95%;
    margin: 0 auto;
  }

  .contactUs-body {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include smallDesktop {
      grid-template-columns: repeat(1, 1fr);
    }
    .contactUs__formArea {
      background-color: #11202a;
      border-radius: 15px;
      padding: 40px;

      @include tablet {
        padding: 30px 20px;
      }

      .contactUs__formArea--heading {
        font-size: 3rem;
        font-weight: 500;
        font-family: "Quicksand", sans-serif;

        @include smallDesktop {
          text-align: center;
        }
        @include tablet {
          font-size: 2rem;
        }
      }

      .contactUs__form {
        padding: 20px 0;
        .contactUs__form--input {
          border: none;
          display: block;
          border-radius: 2px;
          width: calc(100% - 20px);
          padding: 12px;
          margin-bottom: 20px;
          color: gray;
        }
      }
    }
    .contactUs__textArea {
      padding: 70px 0 70px 70px;

      @include smallDesktop {
        padding: 70px 0 0;
      }
      .contactUs__textArea--header {
        margin-bottom: 20px;
      }
      .contactUs__textArea--text {
        font-weight: 500;
        font-family: "Quicksand", sans-serif;
        font-size: 28px;
        text-align: center;
        line-height: 1.5;

        @include tablet {
          font-size: 22px;
        }
      }
      .contactUs__textArea__divider {
        width: 80%;
        margin: 32px auto;
      }
      .commonButton {
        margin: 0 auto;
      }
    }
  }
}
