.ourClients-section {
  padding-bottom: 100px;

  @include tablet {
    padding-bottom: 50px;
  }
  .ourClients-container {
    margin-top: 40px;
    .ourClients__body {
      .ourClients__item {
        .ourClients__item--img {
          width: 100%;
          border: 1px solid #000;
        }
      }
    }

    .slick-arrow {
      z-index: 2;
    }
    .slick-prev {
      left: 15px;
    }
    .slick-next {
      right: 15px;
    }
    .slick-prev:before {
      content: "⮜";
      color: gray;
    }
    .slick-next:before {
      content: "⮞";
      color: gray;
    }
  }

  .clients__section {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    @include tablet {
      grid-template-columns: repeat(1, 1fr);
    }
    .commonTitle--text {
      color: #bb8534;
      font-size: 20px;
      max-width: 200px;
      padding: 10px;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      line-height: 1.4;

      @include tablet {
        max-width: 100%;
        padding: 10px 0;
      }
    }
  }

  .much_more {
    text-align: center;
    margin-top: 20px;
  }
}
