.footer {
  position: relative;
  background-image: url("https://srisrisahajevents.com/wp-content/uploads/2023/04/image.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  height: 400px;
  position: relative;

  @include tablet {
    background-size: 100% 100%;
  }

  .footer-wrapper {
    .footer__logo {
      position: absolute;
      z-index: 2;
      top: 60px;
      width: 160px;
      left: calc(50% - 75px);
      text-align: center;
      .footer__logo--img {
        width: 100%;
      }
    }
    .footer__route {
      .footer__route--text {
        position: absolute;
        z-index: 2;
        top: 240px;
        left: calc(50% - 50px);
        font-family: "Beau Rivage", Sans-serif;
        font-size: 35px;
        font-style: italic;
        color: #feb853;
        transition: all 0.2s;
        cursor: pointer;
        padding: 10px;

        &:hover {
          border-bottom: 4px solid #feb853;
        }
      }
    }
    .footer__social {
      position: absolute;
      z-index: 2;
      top: 330px;
      right: calc(50% - 50px);

      .footer__social--icon {
        margin-left: 18px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: all 0.1s;
        &:hover {
          scale: 1.1;
        }
      }
    }
  }

  .footer-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000be;
  }
}
